import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const MrozyPomnikNiepodleglosciPage = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Pomnik w Kuflewie" />
      <ReturnToPrevPage to={'/gminy/mrozy'}>
        Materiały dotyczące gminy Mrozy
      </ReturnToPrevPage>
      <Header1>Pomnik w Kuflewie</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <p>
          W 1928 roku Komitet Obchodów Dziesięciolecia doprowadził do skutku
          wzniesienie pomnika na skrzyżowaniu drogi Latowicz - Mrozy z drogą do
          rezerwatu Florianów. Głaz pierwotnie nosił napis "Dąb wolności na
          pamiątkę Dziesięciolecia Wskrzeszenia Polski w 1918 roku" oraz był
          ogrodzony płotkiem ze sztachet. Obecnie nie jest ogrodzony, zaś ma
          przymocowaną współczesną tabliczkę "W 100. ROCZNICĘ ODZYSKANIA PRZEZ
          POLSKĘ NIEPODLEGŁOSCI WDZIĘCZNI ZA DAR WOLNEJ OJCZYZNY MIESZKAŃCY
          GMINY MROZY I GMINY CEGŁÓW 11.11.2018r.".
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/mrozy/pomnik-kuflew/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(filter: { relativePath: { regex: "/mrozy/pomnik-kuflew/" } }) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default MrozyPomnikNiepodleglosciPage
